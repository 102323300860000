import {Stores} from '../app-config/Stores'
import {getBrowserLanguages} from '../utils/utils'

interface UserDataKey {
    section: string
    key: string
}

////////////////////////////////////////////////////////////////////////
// keys

const SECTION_PROFILE = 'profile'

const KNOWN_LANGUAGES_KEY = {
    section: SECTION_PROFILE,
    key: 'knownLanguages'
}

/////////////////////////////////////////////////////////////////////////

const cache = new Map()

function combineKey(keys: string[]) {
    return keys.join('#')
}

async function saveUserData(stores: Stores, username: string, userDataKey: UserDataKey, value: any) {
    const {section, key} = userDataKey
    const combinedKey = combineKey([username, section, key])
    const jsonValue = JSON.stringify(value)
    cache.set(combinedKey, jsonValue)

    return stores.userDataStore.save(username, section, key, jsonValue)
}

async function readUserData(stores: Stores, username: string, userDataKey: UserDataKey) {
    const {section, key} = userDataKey
    const combinedKey = combineKey([username, section, key])
    const cachedValue = cache.get(combinedKey)
    if (cachedValue) {
        return JSON.parse(cachedValue)
    }

    const jsonValue = await stores.userDataStore.load(username, section, key)
    if (jsonValue !== undefined) {
        cache.set(combinedKey, jsonValue)
        try {
            return JSON.parse(jsonValue)
        } catch (e) {
            console.error(e)
            cache.delete(combinedKey)
            return null
        }
    }
}

/////////////////////////////////////////////////////////////////////////

export async function readKnownLanguages(stores: Stores, username: string) {
    return await readUserData(stores, username, KNOWN_LANGUAGES_KEY) ?? [] as string[]
}

export async function readKnownLanguagesOrBrowserLanguages(stores: Stores, username: string): Promise<string[]> {
    const knownLanguages = await readKnownLanguages(stores, username)
    if (!knownLanguages || knownLanguages.length === 0) {
        return getBrowserLanguages()
    }
    return knownLanguages
}

export async function saveKnownLanguages(stores: Stores, username: string, knownLanguages: string[]) {
    return await saveUserData(stores, username, KNOWN_LANGUAGES_KEY, knownLanguages)
}