import * as languages from '@cospired/i18n-iso-languages'

export default function getLanguageName(langCode: string, targetLangCode: string): string {
    try {
        // TODO: registerOnce?
        if (targetLangCode === 'ko') {
            languages.registerLocale(require(`./${targetLangCode}.json`));
        } else {
            languages.registerLocale(require(`@cospired/i18n-iso-languages/langs/${targetLangCode}.json`));
        }
        return languages.getName(langCode, targetLangCode)
    } catch (e) {
        // TODO let the user know
        console.log(`getLanguage not supported for ${langCode} in ${targetLangCode}`)
    }
    return ''
}


