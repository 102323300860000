import React from 'react'
import {useHistory} from 'react-router-dom'
import clsx from 'clsx'
import {
    AppBar,
    Button,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Toolbar,
    Typography
} from '@material-ui/core'
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {ButtonProps} from '@material-ui/core/Button'

import {ChevronLeft, ChevronRight, Menu} from '@material-ui/icons'
import {Logout} from 'mdi-material-ui'

import {simpleRouteMap} from '../MainRouter'
import {BehaviorSubject} from 'rxjs'
import {useBehaviorSubject} from '../../utils/utils'
import {MAIN_COLOR} from '../common/common'
import {User} from '../../app-config/Auth'
import AppConfig from '../../app-config/AppConfig'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        title: {
            flexGrow: 1,
        },
    }),
)


export const MainSection: React.FC = props => {
    return <Grid item xs={12}>
        <Paper>
            <div style={{padding: 16}}>
                {props.children}
            </div>
        </Paper>
    </Grid>
}

interface MainTemplateProp {
    user?: User
    config: AppConfig
    subtitle?: string
}

export const SectionTitle: React.FC = props => {
    return <Typography variant='h5'>{props.children}</Typography>
}

export const SectionButton: React.FC<ButtonProps> = props => {
    return <Button variant='contained' color='primary' {...props} />
}

let drawerOpen$ = new BehaviorSubject(false)

export const MainTemplate: React.FC<MainTemplateProp> = props => {
    const {user, subtitle, children, config} = props

    const classes = useStyles()

    const handleSignOut = () => {
        config?.auth.signOut()
    }

    const [drawerOpen, setDrawerOpen] = useBehaviorSubject(drawerOpen$)

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }
        setDrawerOpen(open)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////

    return <div className={classes.root}>
        <CssBaseline/>
        <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: drawerOpen})}>

            <Toolbar>
                {user &&
                <IconButton edge="start" color="inherit" aria-label="menu"
                            onClick={toggleDrawer(true)}
                            className={clsx(classes.menuButton, {[classes.hide]: drawerOpen})}
                >
                    <Menu/>
                </IconButton>
                }
                <Typography variant="h6" className={classes.title} noWrap>
                    Enrichvoca
                    {subtitle && ` / ${subtitle}`}
                </Typography>

                <Typography>
                    {user?.email ?? ''}
                </Typography>
                <div>
                    {user ? <IconButton
                        onClick={handleSignOut}
                        color='inherit'
                    >
                        <Logout/>
                    </IconButton> : <Typography>Welcome!</Typography>
                    }
                </div>
            </Toolbar>
        </AppBar>

        {/* Drawer */}
        {user &&
        <MenuDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer}/>
        }

        <main className={classes.content}>
            <div className={classes.toolbar}/>
            <Grid container spacing={3}>
                {children}
            </Grid>
        </main>
    </div>
}

const MenuDrawer = ({drawerOpen, toggleDrawer}: {
    drawerOpen: boolean, toggleDrawer: (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => void
}) => {
    const history = useHistory()
    const classes = useStyles()
    const theme = useTheme()
    return <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
        })}
        classes={{
            paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            }),
        }}
    >
        <div className={classes.toolbar}>
            <IconButton onClick={toggleDrawer(false)}>
                {theme.direction === 'rtl' ? <ChevronRight/> : <ChevronLeft/>}
            </IconButton>
        </div>
        <Divider/>
        <List style={{marginTop: 0, paddingTop: 0}}>
            {simpleRouteMap((name, routeInfo) => {
                const {path, menuTitle, icon: Icon} = routeInfo
                if (history.location.pathname === path) {
                    return <ListItem key={name} button style={{backgroundColor: MAIN_COLOR}}>
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText>{menuTitle}</ListItemText>
                    </ListItem>
                }
                return <ListItem key={name} button onClick={() => history.push(path)}>
                    <ListItemIcon><Icon/></ListItemIcon>
                    <ListItemText>{menuTitle}</ListItemText>
                </ListItem>
            })}
        </List>
    </Drawer>
}
