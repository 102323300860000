export default interface Deck {
    readonly id: string
    readonly username: string
    readonly name: string
    readonly description?: string | null
    readonly languageCode?: string | null
}

export interface DeckOptionalId extends Omit<Deck, 'id'> {
    readonly id?: string
}

export const DeckOperation = {
    newName: (deck: Deck, newName: string): Deck => {
        return {...deck, name: newName}
    }
}