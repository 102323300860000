import moment from 'moment'
import {UsageInstance} from './UsageInstance'

export const toString = (ui: UsageInstance) => {
    return `${moment(ui.start).format('YYYY-MM-DD')} - ${moment.duration(ui.duration).humanize()}`
}

export const getKey = (ui: UsageInstance) => {
    return moment(ui.start).format('YYYY-MM-DD')
}

export const snap = (ui: UsageInstance): UsageInstance => {
    const start = moment(ui.start).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()
    return {start, duration: ui.duration}
}

export const UsageInstanceOperation = {
    toString,
    getKey,
    snap,
}