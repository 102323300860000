import React, {lazy, Suspense} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {CircularProgress} from '@material-ui/core'
import {SvgIconProps} from '@material-ui/core/SvgIcon'

import {AccountBox, Add, Home, Lock} from '@material-ui/icons'
import {SearchWeb} from 'mdi-material-ui'

import {UserProp} from '../utils/reactUtils'
import {usePromiseLoading} from '../utils/usePromiseLoading'
import {readKnownLanguages} from '../model/UserData'

const DeckMain = lazy(() => import('./deck-main/DeckMain'))

const MainDeckList = lazy(() => import('./main-template/MainDeckList'))
const Profile = lazy(() => import('./main-template/Profile'))
const AddNewDeck = lazy(() => import ('./main-template/AddNewDeck'))
const DeckSettings = lazy(() => import('./main-template/DeckSettings'))
const DeckStats = lazy(() => import('./main-template/DeckStats'))
const SearchLinkManager = lazy(() => import('./main-template/SearchLinkManager'))

const ChangePassword = lazy(() => import('./main-template/ChangePassword'))

export interface RouteInfo {
    path: string
    component: React.FC<UserProp>
    subtitle?: string
    menuTitle?: string
    icon: React.FC<SvgIconProps>
    disabled?: boolean
}


const Main: React.FC<UserProp> = props => {
    const {user, config} = props
    const {stores} = config

    const username = user.userId

    const initialKnownLanguageCodes = usePromiseLoading<string[]>(() => {
        return readKnownLanguages(stores, username)
    })

    if (initialKnownLanguageCodes === undefined) {
        return <CircularProgress/>
    }

    // first time signed in
    if (!initialKnownLanguageCodes || initialKnownLanguageCodes?.length === 0) {
        return <Redirect to={SIMPLE_ROUTES.profile.path}/>
    }

    return <MainDeckList {...props}/>
}

export const SIMPLE_ROUTES = {
    home: {
        path: '/', component: Main,
        menuTitle: 'My Decks',
        icon: Home,
    } as RouteInfo,
    newDeck: {
        path: '/newdeck', component: AddNewDeck,
        menuTitle: 'Add new deck',
        subtitle: 'Add new deck',
        icon: Add,
    } as RouteInfo,
    profile: {
        path: '/profile', component: Profile,
        menuTitle: 'Profile',
        subtitle: 'Profile',
        icon: AccountBox
    } as RouteInfo,
    searchLinks: {
        disabled: true,
        path: '/searchLink', component: SearchLinkManager,
        menuTitle: 'Dictionary/Search',
        subtitle: 'Dictionaries, Searches & Translators',
        icon: SearchWeb
    } as RouteInfo,
    changePassword: {
        disabled: true,
        path: '/changePassword', component: ChangePassword,
        menuTitle: 'Change Password',
        subtitle: 'Change Password',
        icon: Lock
    } as RouteInfo,
}

export const PATH_DECK = '/decks'
export const PATH_DECK_SETTINGS = '/deck-settings'
export const PATH_DECK_STATS = '/deck-stats'

export function simpleRouteMap<T>(mapper: (name: string, routeInfo: RouteInfo) => T): T[] {
    return Object.entries(SIMPLE_ROUTES).filter(([, routeInfo]: [string, RouteInfo]) => !routeInfo.disabled).map(([name, routeInfo]: [string, RouteInfo]) => {
        return mapper(name, routeInfo)
    })
}

export function MainRouter({user, config}: UserProp) {
    return (
        <Router>
            <Suspense fallback={<CircularProgress/>}>
                <Switch>
                    {simpleRouteMap((name, routeInfo) => {
                        const {path, component: Component} = routeInfo
                        return <Route key={name} path={path} exact>
                            <Component user={user} config={config}/>
                        </Route>
                    })}
                    <Route path={`${PATH_DECK}/:deckId`}>
                        <DeckMain config={config}/>
                    </Route>
                    <Route path={`${PATH_DECK_SETTINGS}/:deckId`}>
                        <DeckSettings config={config}/>
                    </Route>
                    <Route path={`${PATH_DECK_STATS}/:deckId`}>
                        <DeckStats config={config}/>
                    </Route>
                    <Route component={NoMatch}/>
                </Switch>
            </Suspense>
        </Router>
    )
}

export function NoMatch() {
    return <div>no such page found</div>
}