import firebase from 'firebase/app'
import 'firebase/firestore'

import moment from 'moment'
import {sortBy} from 'lodash/fp'

import {Stores} from '../Stores'
import Card from '../../shared/Card'
import Deck, {DeckOperation, DeckOptionalId} from '../../shared/Deck'
import SearchLink, {LANG_ANY, LANG_ANYDIFF, LANG_NONE, SearchLinkTemplate} from '../../shared/SearchLink'
import {SearchLinkOperation} from '../../model/SearchLinkOperation'
import {AggregateUsageInstance, DayUsage, UsageInstance} from '../../model/UsageInstance'
import {UsageInstanceOperation} from '../../model/UsageInstanceOperation'


const USERS = 'users'
const DECKS = 'decks'
const CARDS = 'cards'
const USAGES = 'usages'
const STATS = 'stats'

const SEARCH_LINK_TEMPLATES = 'searchLinkTemplates'
const NEXT_CHECK_AFTER = 'nextCheckAfter'
const SOURCE_LANGUAGE_CODE = 'sourceLanguageCode'
const REQUEST_URL = 'requestUrl'
const DATA = 'data'

export type Document = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>

const increment = firebase.firestore.FieldValue.increment(1)
const decrement = firebase.firestore.FieldValue.increment(-1)

function cardToData(card: Card) {
    const data = {...card} as any
    delete data.word
    for (const k in data) {
        if (data[k] === undefined) {
            delete data[k]
        }
    }
    return data
}

function docToCard(deck: Deck, doc: Document): Card {
    const data = doc.data() as any
    return {
        word: doc.id,
        ...data,
        createdAt: data.createdAt?.toDate(),
        nextCheckAfter: data.nextCheckAfter?.toDate(),
        lastCheckedAt: data.lastCheckedAt?.toDate(),
        updatedAt: data.updatedAt?.toDate(),
    }
}

function docToDeck(username: string, doc: Document): Deck {
    return {...doc.data(), username: username, id: doc.id} as Deck
}

export function isEmulatorOn(): boolean {
    if (window.location.hostname === 'localhost') {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', 'http://localhost:8080', false)
        try {
            xhr.send()
            const s = xhr.status
            console.log(s)
            return true
        } catch (e) {
            // console.error(e)
            console.error('emulator not reachable: use production firestore')
        }
    }
    return false
}

const firestoreStore = (): Stores => {
    const db = firebase.firestore()
    const emulator = isEmulatorOn()
    if (emulator) {
        db.settings({
            host: 'localhost:8080',
            ssl: false
        })
    }

    const searchLinkTemplatesRef = db.collection(SEARCH_LINK_TEMPLATES)

    const users = db.collection(USERS)

    function getUserDoc(username: string) {
        return users.doc(username)
    }

    function getDecks(username: string) {
        return getUserDoc(username).collection(DECKS)
    }

    function getDeckDocById(username: string, deckId: string) {
        return getDecks(username).doc(deckId)
    }

    function getDeckDoc(deck: Deck) {
        return getDeckDocById(deck.username, deck.id)
    }

    function getCards(deck: Deck) {
        return getDeckDoc(deck).collection(CARDS)
    }

    function getCardDoc(deck: Deck, card: Card) {
        return getCards(deck).doc(card.word)
    }

    function getUsages(deck: Deck) {
        return getDeckDoc(deck).collection(USAGES)
    }

    function getStats(deck: Deck) {
        return getDeckDoc(deck).collection(STATS)
    }

    return {
        cardStore: {
            updateLevelChange: async (deck: Deck, card: Card, now?: Date) => {
                const ref = getCardDoc(deck, card)
                const data = {
                    level: card.level,
                    lastCheckedAt: card.lastCheckedAt ?? now ?? new Date(),
                    nextCheckAfter: card.nextCheckAfter,
                }
                await ref.update(data)
                return card
            },
            update: async (deck: Deck, card: Card) => {
                const ref = getCardDoc(deck, card)
                const data = cardToData(card)

                await ref.update({
                    ...data,
                    description: card.description ?? firebase.firestore.FieldValue.delete(),
                    contextExamples: card.contextExamples ?? firebase.firestore.FieldValue.delete(),
                })
                return card
            },
            delete: async (deck: Deck, card: Card) => {
                const year = moment(card.createdAt).format('YYYY')
                const month = moment(card.createdAt).format('MM')
                const yearDoc = getStats(deck).doc(year)
                let data = {} as any
                data[month] = decrement
                await db.runTransaction(async (transaction) => {
                    transaction.delete(getCardDoc(deck, card))
                    transaction.set(yearDoc, data, {merge: true})
                })
                // await getCardDoc(deck, card).delete()
                return {}
            },
        },
        deckStore: {
            create: async (input: DeckOptionalId) => {
                if (!input.username) return undefined
                let newInput = {...input} as any
                delete newInput.username
                const docRef = await getUserDoc(input.username).collection(DECKS).add(newInput)
                return {...input, id: docRef.id}
            },

            getList: async (username: string) => {
                const snapshot = await getUserDoc(username).collection(DECKS).get()
                return snapshot.docs.map(doc => docToDeck(username, doc))
            },

            get: async (username: string, deckId: string) => {
                const doc = await getDeckDocById(username, deckId).get()
                if (doc.data()) {
                    return docToDeck(username, doc)
                }
                return undefined
            },

            delete: async (deck: Deck) => {
                await getDeckDoc(deck).delete()
                return {}
            },

            rename: async (deck: Deck, newName: string) => {
                await getDeckDoc(deck).update({
                    name: newName
                })
                const newDeck = DeckOperation.newName(deck, newName)
                return newDeck
            },

            updateDictionaries: async (deck: Deck): Promise<Deck | undefined> => {
                // TODO
                return deck
            },

            getCard: async (deck: Deck, word: string): Promise<Card | undefined> => {
                const cardRef = getCards(deck).doc(word)
                const doc = await cardRef.get()
                if (doc.data()) {
                    return docToCard(deck, doc)
                }
                return undefined

            },

            addCard: async (deck: Deck, card: Card) => {
                // await getCardDoc(deck, card).set(cardToData(card))
                const year = moment(card.createdAt).format('YYYY')
                const month = moment(card.createdAt).format('MM')
                const yearDoc = getStats(deck).doc(year)
                let data = {} as any
                data[month] = increment
                await db.runTransaction(async (transaction) => {
                    transaction.set(getCardDoc(deck, card), cardToData(card))
                    transaction.set(yearDoc, data, {merge: true})
                })
                return {}
            },

            fetchCards: async (deck: Deck, date: Date, limit?: number): Promise<Card[] | undefined> => {
                const cardsRef = getCards(deck).where(NEXT_CHECK_AFTER, '<', date).orderBy(NEXT_CHECK_AFTER, 'desc')
                const ref = limit ? cardsRef.limit(limit) : cardsRef
                const snapshot = await ref.get()
                const results = snapshot.docs.map(doc => docToCard(deck, doc))
                return results
            },

            getAllCards: async (deck: Deck): Promise<Card[] | undefined> => {
                const snapshot = await getCards(deck).get()
                const results = snapshot.docs.map(doc => docToCard(deck, doc))
                return results
            },

            countCards: async (deck: Deck, limit: number) => {
                const snapshot = await getCards(deck).limit(limit).get()
                return snapshot.size
            },

            /////////////////////////////////////////
            addUsage: async (deck: Deck, momentUi: UsageInstance) => {
                const ui = UsageInstanceOperation.snap(momentUi)
                const yearMonth = moment(ui.start).format('YYYY-MM')
                const day = moment(ui.start).format('DD')
                const usageRef = getUsages(deck).doc(yearMonth)
                let data = {} as any
                data[day] = firebase.firestore.FieldValue.increment(ui.duration)
                await usageRef.set(data, {merge: true})
                return undefined
            },

            // getUsages: async (deck) => {
            //     // order by yearMonth
            //     const snapshot = await getUsages(deck).get()
            //     return snapshot.docs.flatMap(doc => {
            //         const yearMonth = doc.id
            //         const data = doc.data()
            //         return sortBy('start', Object.entries(data).map(([day, duration]: [string, number]) => {
            //             const dateStr = `${yearMonth}-${day}`
            //             return {start: moment(dateStr).toDate(), duration}
            //         }))
            //     })
            // },

            getAggregateUsages: async (deck) => {
                // order by yearMonth
                const snapshot = await getUsages(deck).get()
                return snapshot.docs.map(doc => {
                    const yearMonth = doc.id
                    const data = doc.data()
                    const dayUsages = sortBy('day', Object.entries(data).map(([day, duration]: [string, number]) => {
                        return {day, duration} as DayUsage
                    }))
                    return {yearMonth, usages: dayUsages} as AggregateUsageInstance
                })
            },


            getStats: async (deck) => {
                const statsRef = getStats(deck)
                let snapshot = await statsRef.get()
                const monthly = snapshot.docs.flatMap(doc => {
                    const year = doc.id
                    const data = doc.data()
                    return sortBy('month', Object.entries(data).map(([month, count]: [string, number]) => {
                        const yearMonth = `${year}-${month}`
                        return {month: yearMonth, count}
                    }))
                })
                return monthly.filter(({count}) => count !== 0)
            },
        },

        searchLinkStore: {
            getSearchLinks: async (sourceLang: string, targetLangCodes: string[]): Promise<SearchLink[] | undefined> => {
                const snapshot = await searchLinkTemplatesRef.where(SOURCE_LANGUAGE_CODE, 'in', [sourceLang, LANG_ANY]).get()
                // firestore does not allow multiple 'in' filter
                // .where('targetLanguageCode', 'in', [...targetLangCodes, LANG_ANY, LANG_ANYDIFF, LANG_NONE]).get()
                return snapshot.docs.map(doc => doc.data() as SearchLinkTemplate)
                    .filter(template => [...targetLangCodes, LANG_ANY, LANG_ANYDIFF, LANG_NONE].includes(template.targetLanguageCode ?? ''))
                    .flatMap(template => {
                        return SearchLinkOperation.instantiateSearchLinks(template, sourceLang, targetLangCodes)
                    })
                    .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
            },

            create: async (template: SearchLinkTemplate) => {
                await searchLinkTemplatesRef.add(template)
                return {}
            },

            existUrl: async (template: SearchLinkTemplate): Promise<boolean> => {
                const snapshot = await searchLinkTemplatesRef.where(REQUEST_URL, '==', template.requestUrl).get()
                return snapshot.size > 0
            }
        },
        userDataStore: {
            save: async (username: string, section: string, key: string, value: string) => {
                let data: any = {}
                data[key] = value
                await getUserDoc(username).collection(DATA).doc(section).set(data, {merge: true})
                return {}
            },

            load: async (username: string, section: string, key: string): Promise<string | undefined> => {
                if (emulator) {
                    await db.runTransaction(async (transaction) => {
                        const doc = await transaction.get(getUserDoc(username))
                        if (!doc.exists) {
                            transaction.set(getUserDoc(username), {emulator})
                        }
                    })
                }
                const sectionDoc = await getUserDoc(username).collection(DATA).doc(section).get()
                return sectionDoc.data()?.[key]
            }

        }
    }
}

export default firestoreStore