// @ts-ignore
import langs from 'langs'
import {difference} from 'lodash/fp'
import getLanguageName from '../langs/getLanguageName'
import SearchLink, {LANG_ANY, LANG_ANYDIFF, SearchLinkTemplate} from '../shared/SearchLink'

export const TMPL_WORD = '{word}'

const TMPL_URL_TRANSITION: Record<string, (sourceLangCode: string, targetLangCode: string) => string> = {
    '{lang}': (sourceLangCode: string, targetLangCode: string) => sourceLangCode,
    '{tLang}': (sourceLangCode: string, targetLangCode: string) => targetLangCode,
    '{lang3}': (sourceLangCode: string, targetLangCode: string) => {
        try {
            return langs.where('1', sourceLangCode)['3']
        } catch (e) {
            console.error(e)
        }
        return sourceLangCode
    },
    '{tLang3}': (sourceLangCode: string, targetLangCode: string) => {
        try {
            return langs.where('1', targetLangCode)['3']
        } catch (e) {
            console.error(e)
        }
        return targetLangCode
    },
    // for Wiktionary
    '{langInTLang}': getLanguageName
}

export function isMultipleTarget(template: SearchLinkTemplate): boolean {
    return [LANG_ANY, LANG_ANYDIFF].includes(template.targetLanguageCode ?? '')
}


export function getUrl(search: SearchLink, searchWordRaw: string): string {
    const searchWord = searchWordRaw.trim()
    if (!searchWord) return ''
    const requestUrl = search.requestUrlCache || search.requestUrl
    return requestUrl.replace(TMPL_WORD, searchWord)
}

export function instantiate(template: SearchLinkTemplate, sourceLangCode: string, targetLangCode?: string): SearchLink {
    const theTargetLangCode = targetLangCode || sourceLangCode
    let requestUrlCache = template.requestUrl
    for (const tmplKey in TMPL_URL_TRANSITION) {
        const tmplFn = TMPL_URL_TRANSITION[tmplKey]
        const transition = tmplFn(sourceLangCode, theTargetLangCode)
        requestUrlCache = requestUrlCache.replace(tmplKey, transition)
    }
    return {
        ...template,
        selectedSourceLanguageCode: sourceLangCode,
        selectedTargetLanguageCode: (isMultipleTarget(template) ? theTargetLangCode : template.targetLanguageCode ?? ''),
        requestUrlCache
    }
}

export function instantiateSearchLinks(template: SearchLinkTemplate, sourceLang: string, targetLangCodes: string[]): SearchLink[] {
    if (!isMultipleTarget(template)) {
        return [instantiate(template, sourceLang)]
    }
    const theTargetLanguageCodes = template.targetLanguageCode === LANG_ANY ? targetLangCodes : difference(targetLangCodes, [sourceLang])
    return theTargetLanguageCodes.map(target => instantiate(template, sourceLang, target))
}

export const SearchLinkOperation = {
    getUrl,
    instantiateSearchLinks,
    isMultipleTarget,
}