import Auth, {User} from '../Auth'

import firebase from 'firebase/app'
import 'firebase/auth'

interface UserWithFirebaseUser extends User {
    firebaseUser: firebase.User
}

function createUser(firebaseUser: firebase.User | null): UserWithFirebaseUser | null {
    if (!firebaseUser) return null
    return {
        userId: firebaseUser.uid,
        email: firebaseUser.email ?? 'email',
        firebaseUser: firebaseUser,
    }
}

export const firebaseAuth = (): Auth => {
    const auth = firebase.auth()

    return {
        getCurrentAuthenticatedUser: async () => {
            return createUser(auth.currentUser)
        },
        async changePassword(user: User, oldPassword: string, newPassword: string): Promise<any> {
            const currentUser = auth.currentUser
            if (!currentUser) {
                return null
            }
            const credential = firebase.auth.EmailAuthProvider.credential(
                currentUser.email ?? 'email',
                oldPassword
            )
            auth.currentUser?.reauthenticateWithCredential(credential)
            auth.currentUser?.updatePassword(newPassword)
        },
        async signOut() {
            return auth.signOut()
        },
        listenAuthChange(action: () => any): any {
            auth.onAuthStateChanged(user => action())
        }
    }
}

export default firebaseAuth