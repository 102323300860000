import React, {useEffect, useState} from 'react'
import {curry, uniq} from 'lodash/fp'
import {BehaviorSubject, Observable} from 'rxjs'
import {Mutex} from 'async-mutex'
import SearchLink from '../shared/SearchLink'
import {SearchLinkOperation} from '../model/SearchLinkOperation'


export function escapeRegExp(s: string) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function useObservable<T>(observable: Observable<T>, initialValue: T) {
    const [value, setValue] = useState<T>(initialValue)

    useEffect(() => {
        const subscription = observable.subscribe(newValue => {
            setValue(newValue)
        })
        return () => subscription.unsubscribe()
    }, [observable])

    return value
}

export function useBehaviorSubject<T>(observable: BehaviorSubject<T>): [T, (value: T) => void, () => T] {
    const [value, setValue] = useState<T>(observable.value)

    useEffect(() => {
        const subscription = observable.subscribe(newValue => {
            setValue(newValue)
        })
        return () => subscription.unsubscribe()
    }, [observable])

    const newSetValue = (newValue: T) => {
        observable.next(newValue)
    }

    return [value, newSetValue, () => observable.getValue()]
}

export function useStateMachine<T>(observable: BehaviorSubject<T>, mutex: Mutex): [T, (fn: (oldValue: T) => T) => void] {
    const [value, setter, getter] = useBehaviorSubject(observable)
    const updater = (fn: (oldValue: T) => T) => {
        mutex.runExclusive(() => {
            setter(fn(getter()))
        })
    }
    return [value, updater]
}


export function replaceMatchingOneBy<T>(pred: (v: T) => boolean, array: readonly T[], newValue: T): readonly T[] {
    const idx = array.findIndex(pred)
    if (idx < 0) return array
    return [...array.slice(0, idx), newValue, ...array.slice(idx + 1)]
}

export type RSetAction<T> = React.Dispatch<React.SetStateAction<T>>;

export function count<T>(items: readonly T[], predicate: (item: T) => boolean): number {
    return items.reduce((cnt, item) => predicate(item) ? cnt + 1 : cnt, 0)
}

export const _ = curry.placeholder

export function makeComparator(key: string, order = 'asc') {
    return (a: any, b: any) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0

        const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
        const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (aVal > bVal) comparison = 1
        if (aVal < bVal) comparison = -1

        return order === 'desc' ? (comparison * -1) : comparison
    }
}

function getFeature() {
    const screenHeight = window.screen.height
    const screenWidth = window.screen.width
    const width = screenWidth * 0.8
    const height = screenHeight * 0.8
    const feature = screenWidth < 500 ? undefined : `width=${width}, height=${height}, menubar=no, location=yes`
    // console.log(feature)
    return feature
}

export const openDictionaryInNewWindow = (dict: SearchLink, wordRaw: string, windowId?: string, inNewWindow: boolean = true) => {
    windowId = windowId ?? `${dict.id}_${dict.selectedTargetLanguageCode ?? ''}`
    const word = wordRaw.trim()
    const url = SearchLinkOperation.getUrl(dict, word)
    const feature = inNewWindow ? getFeature() : undefined
    if (url) window.open(url, windowId, feature)
}

export function getBrowserLanguages(): string[] {
    return uniq(window.navigator.languages.map(lang => lang.slice(0, 2)))
}