import {useEffect, useState} from 'react'
import {RSetAction} from './utils'

/**
 * This hook execute the promise only when it is needed, and prevent it from execute several times
 * By default, it executes the promise when the result is not set.
 * In case of error, the result will be null and errorHandler will be called if it is given.
 *
 * If the promise returns undefined, the promiseGenerator will be called again
 *
 * @param promiseGenerator
 * @param errorHandler
 */
export function usePromiseLoadingRaw<T>(
    promiseGenerator: () => Promise<T>,
    errorHandler?: (error: any) => void
): [T | undefined | null, RSetAction<T|undefined|null>] {
    const [result, setResult] = useState<T | undefined | null>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (!loading && (result === undefined)) {
            setLoading(true)
            promiseGenerator()
                .then((r) => {
                    setResult(r)
                    setLoading(false)
                })
                .catch(error => {
                    if (errorHandler) {
                        errorHandler(error)
                    }
                    setResult(null)
                    setLoading(false)
                })
        }
    }, [loading, promiseGenerator, errorHandler, result])
    return [result, setResult]
}

export function usePromiseLoading<T>(
    promiseGenerator: () => Promise<T>,
    errorHandler?: (error: any) => void
): T | undefined | null {
    const [result] = usePromiseLoadingRaw(promiseGenerator, errorHandler)
    return result
}
