export const LANG_ANY = 'any'
export const LANG_ANYDIFF = 'anydiff'
export const LANG_NONE = 'none'

export enum SearchType {
    DICTIONARY = 'DICTIONARY',
    TRANSLATOR = 'TRANSLATOR',
    SEARCH = 'SEARCH',
}

export interface SearchLinkTemplate {
    readonly id?: string
    readonly name: string
    readonly searchType: SearchType
    readonly shortName?: string
    readonly description?: string
    readonly sourceLanguageCode?: string
    readonly targetLanguageCode?: string
    readonly requestUrl: string
    readonly iframeAllowed: boolean

    readonly authorId?: string
    readonly priority?: number
    readonly thumbsUp?: number
    readonly thumbsDown?: number
}

export interface SearchLink extends SearchLinkTemplate {
    readonly selectedSourceLanguageCode?: string
    readonly selectedTargetLanguageCode?: string
    readonly requestUrlCache?: string | null
}

export default SearchLink

