import React from 'react'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {SnackbarProvider} from 'notistack'
import {blue} from '@material-ui/core/colors'

export const appTheme = createMuiTheme({
    palette: {
        primary: blue,
    }
})

const AppTemplate: React.FC = props => {
    return <ThemeProvider theme={appTheme}>
        <SnackbarProvider autoHideDuration={3000}>
            {props.children}
        </SnackbarProvider>
    </ThemeProvider>
}

export default AppTemplate