import React, {useEffect} from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import AppConfig from '../AppConfig'
import {useCurrentAuthenticatedUser} from '../../data/memory/userDecks'
import {MainTemplate} from '../../components/main-template/MainTemplate'

import 'firebaseui/dist/firebaseui.css'

let firebaseUiInstance: firebaseui.auth.AuthUI | null = null

const uiConfig: firebaseui.auth.Config = {
    signInOptions: [
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            requireDisplayName: false,
        },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
}

export const FirebaseAuthenticator: React.FC<{ config: AppConfig }> = ({config}) => {
    const user = useCurrentAuthenticatedUser(config)
    if (!firebaseUiInstance) {
        firebaseUiInstance = new firebaseui.auth.AuthUI(firebase.auth())
    }

    useEffect(() => {
        if (!user) {
            firebaseUiInstance?.start('#firebaseui-auth-container', uiConfig)
        }
    }, [user])

    return <MainTemplate config={config}>
        <div style={{margin: '0 auto'}}>
            <div id='firebaseui-auth-container'/>
        </div>
    </MainTemplate>
}

export default FirebaseAuthenticator