import {useEffect} from 'react'
import {BehaviorSubject} from 'rxjs'

import Deck from '../../shared/Deck'
import {useBehaviorSubject} from '../../utils/utils'
import AppConfig from '../../app-config/AppConfig'
import {User} from '../../app-config/Auth'


export interface UserDecks {
    username?: string | null
    decks?: Deck[] | null
}

export const user$ = new BehaviorSubject<User | null | undefined>(undefined)

export const userDecksCache$ = new BehaviorSubject<UserDecks>({})

export function useCurrentAuthenticatedUser(config: AppConfig): User | null | undefined {
    const [user] = useBehaviorSubject(user$)
    if (user === undefined) {
        // console.log(`auth.listenAuthChange`)
        config.auth.listenAuthChange(() => {
            // console.log('auth')
            config.auth.getCurrentAuthenticatedUser().then(u => user$.next(u))
        })
    }
    return user
}

export function useUserDeck(user: User | null | undefined, config: AppConfig): [UserDecks, (v: UserDecks) => void] {
    const username = user?.userId

    const [userDecks, setUserDecks] = useBehaviorSubject(userDecksCache$)

    useEffect(() => {
        if (user && username !== userDecks.username) {
            if (username) {
                config.stores.deckStore.getList(username).then((decks) => {
                    setUserDecks({username, decks})
                })
            } else {
                setUserDecks({username, decks: null})
            }
        }
        // eslint-disable-next-line
    }, [user])
    return [userDecks, setUserDecks]
}

export function replaceDeck(orig: UserDecks, deck: Deck): UserDecks {
    if (!orig.decks) return orig
    let newDecks = [...orig.decks]
    const index = newDecks.findIndex(d => d.id === deck.id)
    if (index >= 0) {
        newDecks[index] = deck
    }
    return {username: orig.username, decks: newDecks}
}