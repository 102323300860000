import {Services} from '../AppConfig'
import firebase from 'firebase'
import {User} from '../Auth'
import Deck from '../../shared/Deck'
import {v4 as uuidv4} from 'uuid'
import {isEmulatorOn} from './firestoreStores'

export const firebaseServices = (): Services => {
    const storage = firebase.storage()
    const functions = firebase.functions()

    if (isEmulatorOn()) {
        functions.useFunctionsEmulator('http://localhost:5001')
    }

    const importCards = functions.httpsCallable('importCards')

    return {
        uploadFile: async (user: User, deck: Deck, file: File) => {
            const name = uuidv4()
            const path = `tmp/${user.userId}/${name}.json`
            const storageRef = storage.ref()
            const fileRef = storageRef.child(path)
            await fileRef.put(file)
            console.log('Uploaded a file!')
            const result = await importCards({path, deckId: deck.id})
            console.log(result)
        }
    }
}