import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/functions'

import AppConfig from '../AppConfig'
import firebaseAuth from './firebaseAuth'
import FirebaseAuthenticator from './FirebaseAuthenticator'
import firestoreStore from './firestoreStores'
import {firebaseServices} from './firebaseServices'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

firebase.initializeApp(config)

export const firebaseAppConfig: AppConfig = {
    stores: firestoreStore(),
    auth: firebaseAuth(),
    authenticator: FirebaseAuthenticator,
    services: firebaseServices(),
}



export default firebaseAppConfig
