import React from 'react'
import {CircularProgress} from '@material-ui/core'
import {MainRouter} from './MainRouter'
import {useCurrentAuthenticatedUser} from '../data/memory/userDecks'
import AppTemplate from './AppTemplate'
import appConfig from '../app-config/firebase/firebaseAppConfig'

function Intro() {
    return (
        <appConfig.authenticator config={appConfig}/>
        //   MainTemplate or Grid hides error toast

        // <MainTemplate>
        //     <Grid item xs={12}>
        //         <MyAuthenticator/>
        //     </Grid>
        // </MainTemplate>
    )
}

function Loading() {
    return <CircularProgress/>
}

function App() {
    const currentUser = useCurrentAuthenticatedUser(appConfig)

    const component = currentUser ?
        <MainRouter user={currentUser} config={appConfig}/> :
        (currentUser === undefined ? <Loading/> : <Intro/>)

    return <AppTemplate>
        {component}
    </AppTemplate>
}

export default App

